import dayjs from 'dayjs';
import moment from 'moment';
import * as yup from 'yup';

import { bulkUpload } from 'api/api';
import { routeToPageMap } from 'constants/constants';
import { STATE_TRANSITIONS } from 'constants/constants';

// Utility function to check if the table dataset received from the backend is valid
export const isTableDatasetValid = (dataset) => {
  // This util checks if the table dataset we received from the backend is valid or not
  if (!dataset || !dataset.data) {
    console.log('Invalid dataset');
    return false;
  }

  if (!Array.isArray(dataset.data) || dataset.data.length === 0) {
    console.log('Invalid data');
    return false;
  }

  if (dataset.headers) {
    if (!Array.isArray(dataset.headers) || dataset.headers.length === 0) {
      console.log('Invalid headers');
      return false;
    }
  } else {
    if (!Array.isArray(dataset.columns) || dataset.columns.length === 0) {
      console.log('Invalid headers');
      return false;
    }
  }

  // You can add more checks here if you want to

  return true;
};

// Utility function to transform admin onboarding data
export const transformAdminOnboardingData = (data) => {
  const { assetStatus, totalPercentage } = data?.data || {};
  const uniqueSources = new Set();

  assetStatus.forEach((asset) => {
    asset.sourceStatus.forEach((source) => {
      // Use a stringified version of the source object as a unique identifier
      const sourceIdentifier = JSON.stringify(source);

      // Check if the source is already added to the set
      if (!uniqueSources.has(sourceIdentifier)) {
        uniqueSources.add(sourceIdentifier);
      }
    });
  });

  // Convert the set back to an array of unique sourceStatus objects
  const uniqueSourcesArray = [...uniqueSources].map((str) => JSON.parse(str));

  const newData = [
    { assetName: 'All', assetPercentage: totalPercentage, assetType: 'All', sourceStatus: uniqueSourcesArray },
    ...assetStatus
  ];

  return newData;
};

// Utility function to get status transition for popover
export const getStatusTransitionForPopover = (status = '', hasLatestOutcome = false) => {
  const popoverItems = (STATE_TRANSITIONS[status] || []).reduce((acc, item) => {
    if (item.value === 'completed' && status === 'work in progress' && !hasLatestOutcome) {
      return acc;
    }
    if (item.value === 'closed' && status === 'on hold' && !hasLatestOutcome) {
      return acc;
    }
    return [...acc, item];
  }, []);

  return popoverItems;
};

// Utility function to transform linkage overview data
export const transformLinkageOverview = (apiData) => {
  const data = apiData || [];
  const transformedData = data?.reduce((result, item) => {
    item.sourceTypeLinkStatus.forEach((status) => {
      const sourceId = status.sourceId;
      if (!result[sourceId]) {
        result[sourceId] = [];
      }
      result[sourceId].push({
        assetType: item.assetType,
        assetName: item.assetName,
        sourceName: status.sourceName,
        sourceTypeTotalLinked: status.sourceTypeTotalLinked,
        sourceTypeTotalNotLinked: status.sourceTypeTotalNotLinked,
        sourceTypeTotalLinkagePercent: status.sourceTypeTotalLinkagePercent,
        isLinked: status.isLinked,
        id: status.id
      });
    });
    return result;
  }, {});

  return transformedData;
};

// Utility function to handle bulk upload
export const handleBulkUpload = async (files, closeModal, type, showToast) => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  if (files.length > 0) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file, file.name);
    });
    try {
      const res = await bulkUpload(formData, userDetails.user.customer.id, type);
      if (res.data.length > 0) {
        showToast('Bulk upload successful', { severity: 'success', duration: 3000 });
      } else {
        showToast('Error Reading File', { severity: 'error', duration: 3000 });
      }
    } catch (err) {
      showToast('Server Error', { severity: 'error', duration: 3000 });
    } finally {
      closeModal();
    }
  } else {
    showToast('Please attach a CSV file.', { severity: 'error', duration: 3000 });
  }
};

// Utility function to check if a value is a number
export const isNumber = (value) => {
  return /^-?\d*\.?\d+$/.test(value);
};

// Utility function to capitalize the first letter of a string
export const capitalizeFirstLetter = (string) => {
  if (!string) return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Utility function to build a query string from an object
export function queryBuilder(finalFilter) {
  const queryArray = [];
  Object.keys(finalFilter).forEach((key) => {
    const keyValue = finalFilter[key];
    let query = '';
    const identifier = queryArray.length ? '&' : '?';
    if (Array.isArray(keyValue)) {
      keyValue.forEach((value) => {
        query += `${query ? '&' : ''}${key}=${value}`;
      });
    } else if (keyValue) {
      query += `${key}=${keyValue}`;
    }
    if (query) {
      queryArray.push(`${identifier}${query}`);
    }
  });
  return queryArray.join('');
}

// Utility function to get text representation based on a percentage value
export const getTextByPercentage = (value) => {
  if (value <= -100) {
    return 'Far Below Target';
  } else if (value < 0) {
    return `${Math.abs(value)}% Below Target`;
  } else if (value === 0) {
    return 'Target Achieved';
  } else if (value <= 100) {
    return `${value}% Above Target`;
  } else {
    return 'Far Above Target';
  }
};

// Utility function to get color variant based on a percentage value
export const getColorVariantFromPercentage = (value) => {
  if (value <= -100) {
    return 'error';
  } else if (value < 0) {
    return 'orange';
  } else if (value === 0) {
    return 'success';
  } else if (value <= 100) {
    return 'success';
  } else {
    return 'success';
  }
};

// Utility function to convert a JSON configuration to a Yup schema
// Helper function to process a single field
const processField = (field) => {
  let validation;

  switch (field.type) {
    case 'email':
      validation = yup
        .string()
        .email(`${field.labelText || field.name} must be a valid email`)
        .required(`${field.labelText || field.name} is required`);
      break;

    case 'text':
    case 'text-area':
    case 'password':
    case 'select':
      validation = yup.string();
      if (field.required) {
        validation = validation.required(`${field.labelText || field.name} is required`);
      }
      if (field.minLength) {
        validation = validation.min(
          field.minLength || 0,
          `${field.labelText || field.name} must be at least ${field.minLength} characters`
        );
      }
      if (field.maxLength) {
        validation = validation.max(field.maxLength, `${field.labelText || field.name} must be at most ${field.maxLength} characters`);
      }
      if (field.regex) {
        validation = validation.matches(
          new RegExp(field.regex),
          field?.regexMessage ? field.regexMessage : `${field.labelText || field.name} is not valid`
        );
      }
      break;

    case 'number':
      validation = yup.number();
      validation = validation.transform((value, originalValue) => (originalValue === '' ? undefined : value));
      validation.nullable();
      if (field.required) {
        validation = validation.required(`${field.labelText || field.name} is required`);
      }
      if (field.min !== undefined) {
        validation = validation.min(field.min, `${field.labelText || field.name} must be at least ${field.min}`);
      }
      if (field.max !== undefined) {
        validation = validation.max(field.max, `${field.labelText || field.name} must be at most ${field.max}`);
      }

      break;

    case 'boolean':
      validation = yup.boolean();
      if (field.required) {
        validation = validation.required(`${field.labelText || field.name} is required`);
      }
      break;

    case 'wysiwyg':
      validation = yup.string();
      if (field.required) {
        validation = validation.required(`${field.labelText || field.name} is required`);
      }
      if (field.minLength) {
        validation = validation.min(field.minLength, `${field.labelText || field.name} must be at least ${field.minLength} characters`);
      }
      break;

    case 'autocomplete':
      validation = yup.mixed();
      if (field.required) {
        validation = validation.test('is-required', `${field.labelText || field.name} is required`, function (value) {
          if (field.multiple) {
            return value && value.length > 0;
          }
          return value !== undefined && value !== null && value !== '';
        });
      }
      break;

    default:
      // Default case for unknown field types
      validation = yup.mixed();
      if (field.required) {
        validation = validation.required(`${field.labelText || field.name} is required`);
      }
  }

  return { name: field.name, validation };
};

// Convert the JSON structure to Yup schema
export const convertJsonToYupSchema = (config) => {
  const schemaFields = {};

  // Helper function to handle nested fields
  const setNestedField = (path, validation) => {
    // Handle nested objects by progressively building the path
    const keys = path.split('.');
    let currentObject = schemaFields;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        currentObject[key] = validation;
      } else {
        if (!currentObject[key]) {
          currentObject[key] = yup.object();
        }
        currentObject = currentObject[key].fields || (currentObject[key] = yup.object().shape({}));
      }
    });
  };

  // Handle both array and object (grouped) structures
  if (Array.isArray(config)) {
    // Flat array structure
    config.forEach((field) => {
      const { name, validation } = processField(field);
      setNestedField(name, validation);
    });
  } else {
    // Grouped structure
    // eslint-disable-next-line no-unused-vars
    Object.entries(config).forEach(([groupName, groupFields]) => {
      groupFields.forEach((field) => {
        const { name, validation } = processField(field);
        setNestedField(name, validation);
      });
    });
  }

  return yup.object().shape(schemaFields);
};

// Utility function to convert a value to pixels
export const convertToPx = (value, parentDimension = null) => {
  if (typeof value === 'number') {
    return value; // Assuming the value is already in pixels
  }

  if (typeof value === 'string') {
    if (value.endsWith('px')) {
      return parseInt(value, 10); // Remove 'px' and convert to integer
    }

    if (value.endsWith('vh')) {
      const vh = parseFloat(value);
      return Math.round((vh / 100) * window.innerHeight); // Convert vh to px based on window height
    }

    if (value.endsWith('%')) {
      const percent = parseFloat(value);
      // Use parentDimension if provided, otherwise use window dimensions
      const dimension = parentDimension || window.innerWidth;
      return Math.round((percent / 100) * dimension); // Convert % to px based on parent or window dimension
    }
  }

  throw new Error(`Unsupported dimension format: ${value}`);
};

// Utility function to get empty form values
export const getEmptyFormValues = (formData) => {
  if (Array.isArray(formData)) {
    return formData.reduce((acc, field) => {
      acc[field.name] = field.defaultValue || '';
      return acc;
    }, {});
  }

  // Handle grouped structure
  return Object.values(formData).reduce((acc, group) => {
    group.forEach((field) => {
      acc[field.name] = field.defaultValue || '';
    });
    return acc;
  }, {});
};

export const dataFrameToTableData = (dataFrame) => {
  const firstObj = dataFrame[0];

  const headers = Object.keys(firstObj).map((key) => {
    return {
      type: 'string', // Default type is 'string
      id: key,
      title: key,
      key,
      display: true,
      label: key
    };
  });

  const data = dataFrame.map((row) => {
    return Object.keys(row).reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {});
  });

  return { headers, data };
};

export const formatCurrency = (num, options = {}) => {
  const { maximumFractionDigits = 1, currencyType = 'USD', formatCurrency = true } = options;

  if (!formatCurrency) {
    return new Intl.NumberFormat(Navigator?.language || 'en-US', {
      style: 'currency',
      currency: currencyType || 'USD',
      maximumFractionDigits: 2,
      currencyDisplay: 'narrowSymbol'
    }).format(num);
  }

  if (num >= 1000) {
    return new Intl.NumberFormat(Navigator?.language, {
      notation: 'compact',
      compactDisplay: 'short',
      style: 'currency',
      currency: currencyType || 'USD',
      maximumFractionDigits,
      currencyDisplay: 'narrowSymbol'
    }).format(num);
  } else {
    return new Intl.NumberFormat(Navigator?.language || 'en-US', {
      style: 'currency',
      maximumFractionDigits: 0,
      currency: currencyType || 'USD'
    }).format(num);
  }
};

export const getUrlParams = (url) => {
  // Create an anchor element to use the browser's built-in URL parser
  const anchor = document.createElement('a');
  anchor.href = url;

  // Get the query string (part after the ?)
  const queryString = anchor.search.substring(1);

  // Split the query string into key-value pairs
  const paramsArray = queryString.split('&');

  // Convert the array of key-value pairs into an object
  const paramsObject = paramsArray.reduce((acc, param) => {
    const [key, value] = param.split('=');
    acc[decodeURIComponent(key)] = decodeURIComponent(value);
    return acc;
  }, {});

  return paramsObject;

  // Example usage:
  // const url = 'https://example.com?param1=value1&param2=value2&param3=value3&param4=value4';
  // const params = getUrlParams(url);
  // console.log(params);
};

export const toUrlParams = (params) => {
  const queryString = Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return queryString;
};

//for pagination in table
export const shouldHideFooter = (totalPages) => totalPages <= 1;

export const shouldFormatString = (str) => {
  return /^[a-zA-Z_]+$/.test(str);
};

// Utility function format values to readable format
const toReadableFormat = (key) => {
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Adds a space before capital letters in camelCase
    .replace(/_/g, ' ') // Replaces underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalizes the first letter of each word
};

export const getDisplayName = (key) => toReadableFormat(key);

export const currencyFormatter = (currencyType = 'USD') =>
  new Intl.NumberFormat(Navigator.language, {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: 2
  });

export const getPageTypeFromPathname = (pathName) => {
  for (const route in routeToPageMap) {
    // Check exact matches first
    if (route === pathName) {
      return routeToPageMap[route];
    }

    // Then check dynamic routes
    if (route.includes(':')) {
      const regexRoute = route.replace(/:\w+/g, '[^/]+');
      const regex = new RegExp(`^${regexRoute}$`);

      if (regex.test(pathName)) {
        return routeToPageMap[route];
      }
    }
  }

  return null;
};

export const isValidDate = (dateString) => {
  const date = moment(dateString, moment.ISO_8601, true);
  return date.isValid();
};

export const getTaskFilterSymbol = (str) => {
  if (str === 'Cost') {
    return '$';
  } else if (str === 'Percentage') {
    return '%';
  } else {
    return '';
  }
};

export const transformText = ({ text, style = 'default' }) => {
  switch (style) {
    case 'capitalize':
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    case 'uppercase':
      return text.toUpperCase();
    case 'lowercase':
      return text.toLowerCase();
    case 'titlecase':
      return text
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    case 'arraytocommaseparatedText':
      return text.join(', ');
    default:
      return text;
  }
};

export const formatNumber = ({ number, format, formatCurrency: shouldFormat, options = {} }) => {
  if (!number && number !== 0) return '-';
  switch (format) {
    case 'currency':
      return formatCurrency(number, {
        formatCurrency: shouldFormat,
        currencyType: options.currencyType,
        ...options
      });
    case 'percentage':
      return `${number}%`;
    case 'data':
      if (number < 1024) {
        return `${number} B`;
      } else if (number < 1024 ** 2) {
        return `${(number / 1024).toFixed(2)} KB`;
      } else if (number < 1024 ** 3) {
        return `${(number / 1024 ** 2).toFixed(2)} MB`;
      } else if (number < 1024 ** 4) {
        return `${(number / 1024 ** 3).toFixed(2)} GB`;
      } else if (number < 1024 ** 5) {
        return `${(number / 1024 ** 4).toFixed(2)} TB`;
      } else if (number < 1024 ** 6) {
        return `${(number / 1024 ** 5).toFixed(2)} PB`;
      } else if (number < 1024 ** 7) {
        return `${(number / 1024 ** 6).toFixed(2)} EB`;
      } else if (number < 1024 ** 8) {
        return `${(number / 1024 ** 7).toFixed(2)} ZB`;
      } else {
        return `${(number / 1024 ** 8).toFixed(2)} YB`;
      }
    case 'number':
      return new Intl.NumberFormat(Navigator.language, { maximumFractionDigits: 0 }).format(number);
    case 'date':
      return number ? moment(number).format('ll') : '-';
    default:
      return number;
  }
};

export const globalFormatter = ({ data, format }) => {
  if (!data && data !== 0) return '-';
  if (/^[a-z]+([A-Z][a-z]*)*$/.test(data)) {
    // * changes camel case text to normal text
    return data?.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
  switch (format) {
    case 'currency':
      return formatCurrency(data);
    case 'number':
    case 'date':
      return formatNumber({ number: data, format });
    default:
      return data;
  }
};

export const cleanPayload = (obj) => {
  // eslint-disable-next-line no-unused-vars
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== '' && v !== null && v !== undefined));
};

// Utility function to escape special characters
export const escapeSpecialChars = (str) => {
  if (!str) return '';

  return str.replace(/\\/g, '\\\\').replace(/[-!$%^&*()+|~=`{}[\]:";'<>?,.@#]/g, '\\$&');
};

export const transformFormToPayload = (formData, values) => {
  if (!formData || !values) return {};

  const result = {};

  Object.keys(formData).forEach((groupKey) => {
    const group = formData[groupKey];
    if (!Array.isArray(group)) return;

    const groupFields = group.map((field) => {
      const fieldName = field.name;
      const value = fieldName.split('.').reduce((obj, key) => obj?.[key], values);

      return {
        ...field,
        value: value || null
      };
    });

    const fieldsWithValues = groupFields.filter((field) => field.value !== null && field.value !== undefined && field.value !== '');

    result[groupKey] = fieldsWithValues.length > 0 ? fieldsWithValues : [];
  });

  return result;
};

// Date handling utilities
export const formatDate = (date) => {
  if (!date) return null;
  const parsedDate = dayjs(date);
  return parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : null;
};

// Form field processing utilities
export const processFieldValue = (value, fieldType) => {
  switch (fieldType) {
    case 'date':
      return formatDate(value);
    case 'number':
      return value !== null ? Number(value) : null;
    default:
      return value;
  }
};

export const processFormFields = (fields, values) => {
  return fields.map((field) => ({
    ...field,
    value: processFieldValue(values[field.name] ?? field.defaultValue ?? null, field.type)
  }));
};

export const initializeFormValues = (formData) => {
  const initialValues = {};
  Object.values(formData).forEach((group) => {
    group.forEach((field) => {
      if (field.defaultValue !== undefined) {
        initialValues[field.name] = processFieldValue(field.defaultValue, field.type);
      }
    });
  });
  return initialValues;
};

export const findFieldConfig = (formData, fieldName) => {
  return Object.values(formData)
    .flat()
    .find((field) => field.name === fieldName);
};
